import map from "lodash/map";
import filter from "lodash/filter";
import find from "lodash/find";
import some from "lodash/some";
import get from "lodash/get";
import isBoolean from "lodash/isBoolean";
import sortBy from "lodash/sortBy";
import last from "lodash/last";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import flatMap from "lodash/flatMap";
import orderBy from "lodash/orderBy";
import includes from "lodash/includes";
import React, { createContext, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { useReconcile } from "@zedoc/react-hooks";
import {
  IN_APP_CONTENT_TYPE__FAQ,
  IN_APP_CONTENT_TYPE__MEDICAL_INFORMATION,
  IN_APP_CONTENT_TYPE__AUTHENTICATION,
} from "../constants";
import { AuthError } from "../store/token";
import { isNotCompleted, getStaged } from "../store/stage";
import useQuestionnaire, {
  useLatestDraftData,
} from "../utils/useQuestionnaire";
import getRejectedParticipations from "../utils/getRejectedParticipations";

const DataContext = createContext();

export const GET_ACTIVITIES = gql`
  query GetActivities {
    state
    my {
      id
      issubscribed
      isaba
      notificationFlag
      notificationPreference
      languagePreference
      activities {
        id
        project {
          id
          name
          logoUrl
          languages
        }
        templates {
          id
          type
          subject
          content
          language
        }
        isConsent
        participationId
      }
      answersSheets {
        id
        state
        patientId
        activityId
        orderInActivity
        pipedResults
        questionnaire {
          id
          name
          minutesToComplete
          translations {
            id
            language
            questionnaireName
          }
        }
      }
    }
    authTemplates {
      id
      type
      subject
      content
      language
      authSettings {
        widgetSettings {
          digitsOnly
          maxCharacters
        }
        widgetType
      }
    }
  }
`;

const rawEmptyData = {
  my: null,
  state: "MISSING",
};

const DataProvider = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const { i18n } = useTranslation();
  const {
    loading,
    data: rawData,
    error,
    refetch,
  } = useQuery(GET_ACTIVITIES, {
    fetchPolicy: "cache-and-network",
  });
  // NOTE: What we are trying to achieve here is to mock a response when
  //       the user does not currently have a valid token. Technically,
  //       this could be handled by the server (which is just returning 401),
  //       but it is easier to do it here and also it reduces the number of
  //       requests to the server of course.
  const data =
    error && error.networkError && error.networkError instanceof AuthError
      ? rawEmptyData
      : rawData;
  const activities = get(data, "my.activities");
  const tokenState = get(data, "state");
  const staged = useSelector(getStaged);
  const myAnswersSheets = get(data, "my.answersSheets");
  const authTemplates = get(data, "authTemplates");

  const byActivityId = useMemo(() => {
    return groupBy(sortBy(myAnswersSheets, "orderInActivity"), "activityId");
  }, [myAnswersSheets]);

  const activitiesByConsent = useMemo(() => {
    const rejectedParticipations = getRejectedParticipations(
      myAnswersSheets,
      activities,
      staged
    );
    const filteredActivities = filter(
      activities,
      (activity) => !includes(rejectedParticipations, activity.participationId)
    );
    return orderBy(
      filteredActivities,
      ["participationId", "isConsent"],
      ["asc", "desc"]
    );
  }, [activities, myAnswersSheets, staged]);

  const currentActivity = useMemo(() => {
    const withNotCompletedAnswersSheet = find(activitiesByConsent, (activity) =>
      some(byActivityId[activity.id], isNotCompleted(staged))
    );
    if (withNotCompletedAnswersSheet) {
      return withNotCompletedAnswersSheet;
    }
    return last(activities);
  }, [activities, byActivityId, staged, activitiesByConsent]);

  const currentAnswersSheet = useMemo(() => {
    return find(
      currentActivity && byActivityId[currentActivity.id],
      isNotCompleted(staged)
    );
  }, [staged, byActivityId, currentActivity]);

  const currentAnswersSheetId = currentAnswersSheet && currentAnswersSheet.id;

  const answersSheets = useMemo(() => {
    const answerSheetsByStaged = filter(
      flatMap(activitiesByConsent, (activity) =>
        map(byActivityId[activity.id], ({ id, state, questionnaire }) => ({
          id,
          state,
          questionnaire,
          draftState: staged[id] && staged[id].state,
          isConsent: activity.isConsent,
        }))
      ),
      (answersSheet) =>
        !answersSheet.isConsent ||
        (answersSheet.isConsent && answersSheet.state !== "COMPLETED")
    );

    return answerSheetsByStaged;
  }, [staged, byActivityId, activitiesByConsent]);

  const projectLogoUrl = get(currentActivity, "project.logoUrl");
  const projectLanguagePreference = useReconcile(
    get(currentActivity, "project.languages")
  );
  const patientLanguagePreference = useReconcile(
    get(data, "my.languagePreference")
  );
  useLatestDraftData(currentAnswersSheetId);

  const {
    isFirstInActivity,
    isStarted,
    // isCompleted,
    // mostRecentDraft,
    // localDraftNeedsUpdate,
    loadingQuestionnaire,
    translations,
    // currentQuestionId,
  } = useQuestionnaire(currentAnswersSheetId, null, {
    preferRemoteDraftIfNewer: true,
  });
  const authTemplate = filter(
    authTemplates,
    (template) => template.type === IN_APP_CONTENT_TYPE__AUTHENTICATION
  );
  const hasAuthTemplate = !isEmpty(authTemplate);
  const faqTemplates = filter(
    currentActivity?.templates,
    (template) =>
      template.type === IN_APP_CONTENT_TYPE__FAQ &&
      template.language === i18n.language
  );
  const hasFaq = !isEmpty(faqTemplates);
  const medicalInformationTemplates = filter(
    currentActivity?.templates,
    (template) =>
      template.type === IN_APP_CONTENT_TYPE__MEDICAL_INFORMATION &&
      template.language === i18n.language
  );
  const hasMedicalInformation = !isEmpty(medicalInformationTemplates);

  const value = useMemo(
    () => ({
      data,
      answersSheetId: currentAnswersSheetId,
      currentAnswersSheet,
      currentActivity,
      answersSheets,
      translations,
      projectLanguagePreference,
      patientLanguagePreference,
      projectLogoUrl,
      loading,
      loadingQuestionnaire,
      tokenState,
      shouldContinueActivity:
        isBoolean(isFirstInActivity) &&
        isBoolean(isStarted) &&
        (!isFirstInActivity || isStarted),
      faqTemplates,
      hasFaq,
      medicalInformationTemplates,
      hasMedicalInformation,
      hasAuthTemplate,
      authTemplate,
      refetch,
    }),
    [
      data,
      currentAnswersSheetId,
      currentAnswersSheet,
      currentActivity,
      answersSheets,
      translations,
      projectLanguagePreference,
      patientLanguagePreference,
      projectLogoUrl,
      loading,
      loadingQuestionnaire,
      tokenState,
      isFirstInActivity,
      isStarted,
      faqTemplates,
      hasFaq,
      medicalInformationTemplates,
      hasMedicalInformation,
      hasAuthTemplate,
      authTemplate,
      refetch,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;

export const useDataProvider = () => useContext(DataContext);
