import isPlainObject from "lodash/isPlainObject";
import {
  LOCATION_CHANGE,
  replace as historyReplace,
  push,
} from "connected-react-router";
import { REHYDRATE } from "redux-persist";
import { encodeQuery, urlJoin } from "@zedoc/url";
import settings from "../../utils/settings";
import { createNotifyError } from "../../utils/notify";
import getSecretChallenge from "./getSecretChallenge";
import { CLOCK_ACTION, selectClockAsTimestamp } from "../../utils/clock";
import { isStageAction } from "../stage";
import {
  ACTION_LOGIN,
  ACTION_LOGOUT,
  ACTION_EXCHANGE,
  ACTION_REFRESH,
  ACTION_UPDATE,
  ACTION_FLUSH,
  ACTION_START_SURVEY,
  login,
  logout,
  update,
  replace,
  exchange,
  startSurvey,
} from "./actions";
import {
  selectAuthJwt,
  selectIsLoggedIn,
  selectSecret,
  selectSessionJwt,
  selectRefreshJwt,
  selectTokenFromHash,
  selectLastActivityTs,
  selectLocation,
} from "./selectors";
import AuthError from "./AuthError";
import { getUserLanguage } from "../preferences";

const {
  patientAuthLoginUrl,
  patientAuthTokenUrl,
  patientSurveyProjectsUrl,
  patientRefreshTokenDelaySeconds,
  patientMaxAllowedInactivitySeconds,
} = settings.public;

/**
 * @param {number} lastActivityTs
 */
const getSessionLogoutDelayMs = (lastActivityTs) => {
  return Math.max(
    0,
    patientMaxAllowedInactivitySeconds * 1000 - (Date.now() - lastActivityTs)
  );
};

/**
 * @param {number} lastActivityTs
 */
const isWithinAllowedInactivityPeriod = (lastActivityTs) => {
  if (!patientMaxAllowedInactivitySeconds) {
    return true;
  }
  return getSessionLogoutDelayMs(lastActivityTs) > 0;
};

/**
 * Returns true if action is LOCATION_CHANGE and it's not the first rendering.
 * @param {unknown} action
 */
const isRelevantLocationChange = (action) => {
  if (!isPlainObject(action)) {
    return false;
  }
  if (action.type !== LOCATION_CHANGE) {
    return false;
  }
  if (action.payload && action.payload.isFirstRendering) {
    return false;
  }
  return true;
};

/**
 * @param {object} options
 * @param {() => Promise<void>} options.purge
 * @param {() => Promise<void>} options.flush
 */
const createMiddleware = ({ purge, flush }) => {
  return (store) => {
    /** @type {number} */
    let lastActivityTs = Date.now();
    let sessionLogoutTimeout = null;

    /**
     * @param {number} [timestamp]
     */
    const observeRelevantUserActivity = (timestamp = Date.now()) => {
      lastActivityTs = timestamp;
      if (patientMaxAllowedInactivitySeconds) {
        if (sessionLogoutTimeout) {
          clearTimeout(sessionLogoutTimeout);
        }
        const delayMs = getSessionLogoutDelayMs(lastActivityTs);
        sessionLogoutTimeout = setTimeout(() => {
          store.dispatch(logout());
        }, delayMs);
      }
    };

    /**
     * @param {object} options
     * @param {string} [options.surveyJwt]
     * @param {string} [options.projectId]
     */
    const callSurveyEndpoint = ({ surveyJwt, projectId }) => {
      if (!patientSurveyProjectsUrl) {
        return Promise.reject(
          new Error(
            "Cannot obtain token because patientSurveyProjectsUrl is not set"
          )
        );
      }
      if (!surveyJwt) {
        return Promise.reject(
          new Error("Cannot obtain token because surveyJwt empty")
        );
      }
      if (!projectId) {
        return Promise.reject(
          new Error("Cannot obtain token because projectId not valid")
        );
      }

      return fetch(urlJoin(patientSurveyProjectsUrl, projectId), {
        method: "POST",
        headers: {
          authorization: surveyJwt,
        },
      });
    };

    /**
     * @param {object} options
     * @param {string} options.surveyJwt
     * @param {string} options.projectId
     */
    const startSurveyProcess = ({ surveyJwt, projectId }) => {
      return callSurveyEndpoint({ surveyJwt, projectId })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          if (
            response.status === 401 ||
            response.status === 403 ||
            response.status === 500
          ) {
            // NOTE: Apparently the survey link is not valid anymore,
            //       either because the JWT expired or it was explicitly
            //       invalidated (inactive = true). Unfortunately, in the
            //       second case the server will currently return 500.
            store.dispatch(push("/invalid-token"));
            store.dispatch(logout());
          }
          throw new AuthError(
            `Failed to generate token: ${response.statusText}`
          );
        })
        .then((data) => {
          let sessionJwt;
          if (data.sessionJwt) {
            sessionJwt = data.sessionJwt;
          } else if (data?.body?.sessionJwt) {
            sessionJwt = data.body.sessionJwt;
          } else {
            throw new AuthError("Session token not found");
          }
          store.dispatch(
            update({
              sessionJwt,
              startingSurvey: false,
            })
          );
        })
        .catch((err) => {
          console.log("error", err);
          createNotifyError();
        });
    };

    /**
     * @param {object} options
     * @param {string} [options.refreshJwt]
     * @param {string} [options.exchangeJwt]
     * @param {string} [options.secret]
     */
    const callTokenEndpoint = ({ refreshJwt, exchangeJwt, secret }) => {
      if (!patientAuthTokenUrl) {
        return Promise.reject(
          new Error(
            "Cannot obtain token because patientAuthTokenUrl is not set"
          )
        );
      }
      const query = {};
      const state = store.getState();
      if (exchangeJwt) {
        query.exchangeJwt = exchangeJwt;
        query.secret = secret;
      } else if (refreshJwt) {
        query.refreshJwt = refreshJwt;
      }
      return fetch(`${patientAuthTokenUrl}${encodeQuery(query)}`, {
        method: "POST",
        headers: {
          authorization: selectAuthJwt(state),
        },
      });
    };

    /**
     * @param {string} exchangeJwt
     * @param {string} secret
     */
    const exchangeToken = (exchangeJwt, secret) => {
      return callTokenEndpoint({ exchangeJwt, secret })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(`Failed to exchange token: ${response.statusText}`);
        })
        .then((data) => {
          const { refreshJwt, sessionJwt } = data;
          store.dispatch(
            update({
              refreshJwt,
              sessionJwt,
            })
          );
        })
        .catch(createNotifyError());
    };

    /**
     * @param {string} oldRefreshToken
     */
    const refreshToken = (oldRefreshToken) => {
      if (!isWithinAllowedInactivityPeriod(lastActivityTs)) {
        // NOTE: This is only a safety double-check, because the session will
        //       be logged out anyway via sessionLogoutTimeout.
        return Promise.reject(
          new AuthError("User will be logged out due to inactivity")
        );
      }
      return callTokenEndpoint({ refreshJwt: oldRefreshToken })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          if (response.status === 401) {
            // NOTE: This means that most likely the refresh token has expired,
            //       so there's nothing else we can do but logout completely.
            store.dispatch(logout());
          }
          throw new AuthError(
            `Failed to exchange token: ${response.statusText}`
          );
        })
        .then((data) => {
          const { refreshJwt, sessionJwt } = data;
          store.dispatch(
            update({
              refreshJwt,
              sessionJwt,
            })
          );
          return {
            sessionJwt,
            refreshJwt,
          };
        })
        .catch(createNotifyError());
    };

    let loggingOut = null;
    const waitForLogout = (isLoggedIn) => {
      if (loggingOut) {
        return loggingOut;
      }
      loggingOut = isLoggedIn
        ? purge().then(() => {
            loggingOut = null;
          })
        : Promise.resolve();
      return loggingOut;
    };

    let refreshTokenTimeout = null;
    const maybeScheduleTokenRefresh = () => {
      if (patientRefreshTokenDelaySeconds && !refreshTokenTimeout) {
        refreshTokenTimeout = setTimeout(() => {
          const refreshJwt = selectRefreshJwt(store.getState());
          if (refreshJwt) {
            refreshToken(refreshJwt).catch((err) => {
              console.error("Failed to refresh token", err);
            });
          }
          refreshTokenTimeout = null;
        }, patientRefreshTokenDelaySeconds * 1000);
      }
    };
    return (next) => (action) => {
      if (!isPlainObject(action)) {
        return next(action);
      }
      if (isStageAction(action) || isRelevantLocationChange(action)) {
        observeRelevantUserActivity();
      }
      switch (action.type) {
        case REHYDRATE: {
          // NOTE: We first call next(action) to make sure that state
          //       has the value after rehydration.
          const result = next(action);
          const state = store.getState();
          const authJwt = selectAuthJwt(state);
          const sessionJwt = selectSessionJwt(state);
          const previousLastActivityTs = selectLastActivityTs(state);
          if (previousLastActivityTs) {
            observeRelevantUserActivity(previousLastActivityTs);
          }
          if (authJwt && !sessionJwt) {
            const { tokenType } = selectTokenFromHash(state);
            if (!tokenType) {
              // It looks like the login was previously interrupted because authJwt
              // is present but sessionJwt isn't. Instead of trying to fix it we logout
              // completely in order to return to the last known good state. However,
              // if only do it if token is not present in the URL at the moment. If it is,
              // then most likely something different needs to happen and
              // it will be handled via LOCATION_CHANGE branch.
              store.dispatch(logout());
            }
          }
          return result;
        }
        case CLOCK_ACTION: {
          const lastClockTs = selectClockAsTimestamp(store.getState());
          if (lastActivityTs && lastActivityTs > lastClockTs) {
            return next({
              ...action,
              meta: {
                ...action.meta,
                lastActivityTs,
              },
            });
          }
          return next(action);
        }
        case LOCATION_CHANGE: {
          const { isFirstRendering } = action.payload || {};
          if (isFirstRendering) {
            observeRelevantUserActivity();
            const { token, tokenType } = selectTokenFromHash(store.getState());
            if (tokenType === "t") {
              // We are replacing the entire state here because patient
              // might have a received a different type of token this time,
              // in which case we'd better remove the existing credentials
              // in case they're still valid.
              store.dispatch(replace({ sessionJwt: token, lastActivityTs }));
            }
            if (tokenType === "a") {
              const authJwt = selectAuthJwt(store.getState());
              if (patientAuthLoginUrl) {
                const sessionJwt = selectSessionJwt(store.getState());
                if (authJwt !== token || !sessionJwt) {
                  store.dispatch(login({ authJwt: token, lastActivityTs }));
                }
              } else {
                const { secret } = getSecretChallenge();
                store.dispatch(
                  replace({ sessionJwt: token, lastActivityTs, secret })
                );
                return flush().then(() => {
                  window.location = "/auth/login";
                });
              }
            }
            if (tokenType === "e") {
              if (!patientAuthLoginUrl) {
                const sessionJwt = selectSessionJwt(store.getState());
                const secret = selectSecret(store.getState());
                if (sessionJwt) {
                  store.dispatch(
                    replace({ authJwt: sessionJwt, sessionJwt: "", secret })
                  );
                }
              }
              store.dispatch(exchange({ exchangeJwt: token }));
            }
            if (tokenType === "s") {
              const surveyJwt = token;
              const projectId = new URLSearchParams(window.location.search).get(
                "projectId"
              );
              store.dispatch(replace({}));
              store.dispatch(startSurvey({ surveyJwt, projectId }));
            }
            if (tokenType) {
              const location = selectLocation(store.getState());
              store.dispatch(
                historyReplace({
                  ...location,
                  hash: "",
                })
              );
            }
          }
          return next(action);
        }
        case ACTION_LOGIN: {
          // if (!patientAuthLoginUrl) {
          //   next(action);
          //   return Promise.reject(
          //     new Error("Cannot login because patientAuthLoginUrl is not set")
          //   );
          // }
          const { secret, secretChallenge } = getSecretChallenge();
          next({
            ...action,
            payload: {
              ...action.payload,
              secret,
              lastActivityTs: Date.now(),
            },
          });
          const state = store.getState();
          // NOTE: If action.payload.authJwt was present then we will select it here.
          const authJwt = selectAuthJwt(state);
          const language = getUserLanguage(state);
          return flush().then(() => {
            window.location = `${patientAuthLoginUrl}${encodeQuery({
              i18n: language,
              authJwt,
              secretChallenge,
            })}`;
          });
        }
        case ACTION_UPDATE: {
          maybeScheduleTokenRefresh();
          return next(action);
        }
        case ACTION_EXCHANGE: {
          const { exchangeJwt } = action.payload || {};
          const state = store.getState();
          // NOTE: We need to select secret before next(action) because ACTION_EXCHANGE
          //       will clear the secret from the state.
          const secret = selectSecret(state);
          next(action);
          if (!secret) {
            return Promise.reject(
              new Error("Cannot exchange token because secret is not set")
            );
          }
          return exchangeToken(exchangeJwt, secret);
        }
        case ACTION_START_SURVEY: {
          const { projectId, surveyJwt } = action.payload || {};
          next(action);
          return startSurveyProcess({ projectId, surveyJwt });
        }
        case ACTION_REFRESH: {
          const state = store.getState();
          const refreshJwt = selectRefreshJwt(state);
          if (!refreshJwt) {
            next(action);
            return Promise.reject(
              new AuthError(
                "Cannot refresh token because refreshJwt is not set"
              )
            );
          }
          next(action);
          return refreshToken(refreshJwt);
        }
        case ACTION_LOGOUT: {
          const isLoggedIn = selectIsLoggedIn(store.getState());
          next(action);
          return waitForLogout(isLoggedIn);
        }
        case ACTION_FLUSH: {
          next(action);
          return flush();
        }
        default: {
          return next(action);
        }
      }
    };
  };
};

export default createMiddleware;
